jQuery(function () {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover('show');

  var catSelect = $('select.categories');

  if(catSelect.length > 0) {
    if(document.location.search != "") {
      var selectedCat = document.location.search.match(/=.*/)[0].replace('=', '');
      console.log(selectedCat);
      $("select.categories option[value=" + selectedCat + "]").attr('selected', 'selected');
    }

    catSelect.change(function(){
      var cat = $(this).val();
      window.location = '/browse?category=' + cat;
    });
  }

  jQuery.validator.addMethod("phoneUS", function(phone_number, element) {
      phone_number = phone_number.replace(/\s+/g, "");
      return this.optional(element) || phone_number.length > 9 && phone_number.match(/^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/);
  }, "Please specify a valid phone number");

  jQuery.validator.addClassRules({
    phone: {
      required: true,
      phoneUS: true
    }
  });

  function validateForm(form){

    form.validate({
      onfocusout: false,
      rules: {
        name: 'input.custom.required'
      },
      ignore: "",
      errorPlacement: function(error, element) {
      var errorMsg = element.data("invalidmsg");
      if (errorMsg != null){
        error.text(errorMsg);
      }
      var p = element.parent();
      if (p.hasClass('no-error-parent')){
        p = p.parent();
      }
      p.append(error);
    }});
  }

  $("form.validate").each(function(){
    validateForm($(this));
  });

  var sliders = $('.slider');
  var slideImages = sliders.find('.slide img');
  var options = {
    animation: 'fade',               // fade, horizontal-slide, vertical-slide, horizontal-push
    animationSpeed: 800,             // how fast animtions are
    timer: true, 			              // true or false to have the timer
    advanceSpeed: 4000, 		          // if timer is enabled, time between transitions
    pauseOnHover: false, 		        // if you hover pauses the slider
    startClockOnMouseOut: false, 	  // if clock should start on MouseOut
    startClockOnMouseOutAfter: 1000, // how long after MouseOut should the timer start again
    directionalNav: false, 		      // manual advancing directional navs
    captions: true, 			          // do you want captions?
    bullets: true,            			  // true or false to activate the bullet navigation
    bulletThumbs: true,		          // thumbnails for the bullets
    bulletThumbLocation: '', // location from this file where thumbs will be
    centerBullets: false,
    fluid: true,                     // or set a aspect ratio for content slides (ex: '4x3')
    captionAnimation: 'slideOpen'
  };

  sliders.each(function () {
    var slider = $(this);
    options.directionalNav = slider.hasClass('nav-arrows');
    if (slider.hasClass('no-auto-advance')) {
      options.timer = false;
    }
    slider.orbit(options);
    slider.show();
  });

  $('.tablesort').tablesorter();

  $('#experts-form').submit(function () {
    if ($('#password').val() == '') {
      $('#password').attr('disabled', 'disabled');
      $('#confirm-password').attr('disabled', 'disabled');
    }
    return true;
  });

  $('.datepicker').datetimepicker({
    format: 'YYYY-MM-DD'
  });

  $("input[data-role=tagsinput], select[multiple][data-role=tagsinput]").tagsinput();

  //sets timer variable
  var timer;

  // when the button and button menu are hovered
  $('.user-avatar.dropdown, .user-avatar .dropdown-menu').hover(function () {

    // Clears the time on hover to prevent a que or waiting for the delay to finish from a previous hover event
    clearTimeout(timer);
    // Add the class .open and show the menu
    // $('.dropdown').addClass('show');
    $('.user-avatar .dropdown-menu').addClass('show');
  }, function () {

    // Sets the timer variable to run the timeout delay
    timer = setTimeout(function () {
      // remove the class .open and hide the submenu
      // $('.dropdown').removeClass("show");
      $('.user-avatar .dropdown-menu').removeClass("show");
    }, 500);

  });

  function formatState(state) {
    if (!state.id) {
      return state.text;
    }
    var $state = $(
      `<div class="search-selectable-item">
        <img src="${state.avatar}" class="avatar-item mr-2" />
        <span class="d-flex flex-column">
          <span><b>${state.text}</b></span>
          <span>${state.email}</span>
        </span>
      </div>`
    );
    return $state;
  };

  $('.select2').select2({
    placeholder: 'Search...',
    templateResult: formatState,
    ajax: {
      url: $(".select2").data('target'),
      delay: 500,
      dataType: 'json',
      data: function (params) {
        var query = {
          search: params.term,
          page: params.page || 1
        }
        return query;
      }
    }
  });

  $('.select2').on('select2:select', function (e) {
    if ($(this).data('redirect') != 'no') {
      var data = e.params.data;
      window.location.href = data.path; 
    }
  });
  
  // selection
  $('.local-select2').select2({
    theme: 'bootstrap4'
  });

  // admin / expert / hours tab
  $('.local-select2').on('select2:select', function (e) {
    $('#hours_submit_btn').trigger('click');
  });
  // form validation
  $("form").validate();
});
