// import "jquery-migrate";
// import "jquery-expander";
import 'select2';
import "jquery-validation";
import "script-loader!./vendor/tagsinput";
import "script-loader!./vendor/jquery.orbit-1.4.0";
// import "script-loader!./vendor/jquery.parsequery.js";
import "script-loader!./vendor/jquery.fn.sortable.js";
import "script-loader!./vendor/jquery.tablesorter.min.js"
import "script-loader!./vendor/jquery.quicksearch.js"
import "script-loader!./vendor/jqueryjcrop.js"
// import "script-loader!./vendor/jquery.placeholder.min";
// import "./vendor/jquery.tagsinput.js"
// import "./vendor/jquery.reveal.js";

jQuery.fn.extend({
  live: function (event, callback) {
    if (this.selector) {
      jQuery(document).on(event, this.selector, callback);
    }
    return this;
  }
});