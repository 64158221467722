jQuery(function() {

  autosize($('textarea'));

  function refresh_featured_job_title_list(initial_load = false) {
    var selected_job_title_values = []

    $('.job_title_select').each(function(index) {
      var $selected_option = $(this).find('option:selected');
      var selected_value = $selected_option.prop('value');
      if ( selected_value === '') { return }
      selected_job_title_values.push(selected_value);

      var $featured_job_title = $('.featured_job_title_select');

      if ( $featured_job_title.find('option[value="' + selected_value + '"]').length > 0 ) { return  }

      $featured_job_title.append($selected_option.clone().attr('selected', false));
    });

    $('.featured_job_title_select option').each(function(index) {
      var $this = $(this);

      if ( selected_job_title_values.includes($this.prop('value')) ) { return }

      $this.remove();
    });

    if (initial_load) {
      $('.featured_job_title_select').val($('.featured_job_title_select').data('original'));
    }
  };

  $('.job_titles_wrapper').on('change', '.job_title_select', function(event) {
    refresh_featured_job_title_list();
    $('.local-select2.featured_job_title_select').select2();
  });

  refresh_featured_job_title_list(true);
  
  // handle URL when switching tab
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    history.pushState({}, '', `${window.location.pathname}${e.target.hash}`);
  });

  var hash = document.location.hash;
  if (hash) {
    $('.nav-tabs a[href="' + hash + '"]').tab('show');
  }

});
