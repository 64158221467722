import "images";
// import "fonts";
import "stylesheets/application";

// These polyfills ensure that modern JS features
import "core-js/stable"
import "regenerator-runtime/runtime"

import "modules/bootstrap";
import "modules/landkit";

import "modules/autosize";
import "modules/sortable";
import "modules/clipboard";
import "modules/chartist";
import "modules/moment";

import "modules/jquery-plugins";
import "modules/datetimepicker";

import "modules/config";
import "modules/profile";
import "modules/subregion_select";
import "modules/dashboard";

import "modules/experts";
import "modules/reports";
import "modules/scheduler";
import "modules/site";

import Rails from "@rails/ujs"
Rails.start();
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// this needs to be after Rails.start()
// https://github.com/ifad/data-confirm-modal
require('data-confirm-modal');

dataConfirmModal.setDefaults({
  title: 'Please Confirm',
  commit: 'OK',
  cancel: 'Cancel'
});
