window.PivotAdmin = window.PivotAdmin || {};

PivotAdmin.ConferenceTypesReport = function (rawData, chartElementSelector) {
  this.rawData = rawData;
  this.chartElementSelector = chartElementSelector;

  this.chartData = this.buildChartData();
};

PivotAdmin.ConferenceTypesReport.prototype = {
  renderChart: function () {
    var self = this;

    new Chartist.Pie(self.chartElementSelector,
      self.chartData, self.chartOptions());
  },

  chartOptions: function () {
    var self = this;

    return {
      labelInterpolationFnc: function (value) {
        var pct = Math.round(self.rawData[value] /
          self.chartData.series.reduce(Chartist.sum) * 100);
        return PivotAdmin.Utils.capitalizeFirstLetter(value) + ": " + pct + '%';
      },
      labelPosition: 'outside',
      labelDirection: 'explode',
      chartPadding: 100,
      labelOffset: 20,
      startAngle: 270,
      ignoreEmptyValues: true,
    };
  },

  buildChartData: function () {
    var labels = [];
    var series = [];

    for (var k in this.rawData) {
      labels.push(k);
      series.push(this.rawData[k]);
    }

    return {
      labels: labels,
      series: series
    };
  }
};

PivotAdmin.Utils = {
  capitalizeFirstLetter: function (input) {
    if (typeof input !== 'string') {
      input += '';
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
};

// search

PivotAdmin.SearchReport = function (chartDataUrl, chartElementSelector) {
  this.chartDataUrl = chartDataUrl;
  this.chartElementSelector = chartElementSelector;
};

PivotAdmin.SearchReport.prototype = {
  renderChart: function () {
    var self = this;

    $.getJSON(this.chartDataUrl, function (data) {
      new Chartist.Bar(self.chartElementSelector,
        self.cleanChartData(data),
        self.chartOptions());
    });
  },

  chartOptions: function () {
    return {
      chartPadding: {
        top: 20,
        right: 0,
        bottom: 30,
        left: 0
      },
      axisY: {
        onlyInteger: true
      },
      plugins: [
        Chartist.plugins.ctAxisTitle({
          axisX: {
            axisTitle: 'Day of the Month',
            axisClass: 'ct-axis-title',
            offset: {
              x: 0,
              y: 50
            },
            textAnchor: 'middle'
          },
          axisY: {
            axisTitle: 'Count',
            axisClass: 'ct-axis-title',
            offset: {
              x: 0,
              y: 10
            },
            textAnchor: 'middle',
            flipTitle: true
          }
        })
      ]
    }
  },

  cleanChartData: function (data) {
    var mod = 1;

    if (data.labels.length >= 28 && data.labels.length < 45) {
      mod = 2;
    } else if (data.labels.length > 45 && data.labels.length < 100) {
      mod = 5;
    } else if (data.labels.length > 100) {
      mod = 7;
    }

    $.each(data.labels, function (i, el) {
      if (i % mod == 0) {
        data.labels[i] = el.slice(5, 10).replace("-", "/");
      } else {
        data.labels[i] = "";
      }
    });

    return data;
  }
};
