import consumer from "./consumer"

window.cable = consumer.subscriptions.create("ExportChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    // console.log("notification connected.");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    // console.log("notification dis-connected.");
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log("export data received: ", data);
    if (data.message == 'pong') {
      return;
    }
    
    if (data.s3_url) {
      window.location.replace(data.s3_url);
    }
    
    let $btn = $('#export-btn');
    
    if ($btn.data('text')) {
      $btn.html($btn.data('text'));
    } else {
     $btn.html('Export to CSV'); 
    }
    
    if ($btn.attr('href') == '#') {
      $btn.attr('href', data.s3_url);
    }
    
    $btn.removeClass('disabled');
  }
});
