$(document).ready(function () {
  if ($('select#user_country').length > 0) {
    $('select#user_country').change(function (event) {
      var select_wrapper = $('#user_state_wrapper');
      $('select', select_wrapper).attr('disabled', true);
      var country_code = $(this).val();
      var url = '/location_subregions?parent_region=' + country_code;
      select_wrapper.load(url, function() {
        $('.local-select2').select2({
          theme: 'bootstrap4'
        });
      });
    });
  }
});