$(document).ready(function () {
  MAX_CLIENT_SELECTION = 0;
  MIN_CLIENT_SELECTION = 1;

  if ( $('table.scheduler.new').length === 1 ) {
    MAX_CLIENT_SELECTION = 10;
    MIN_CLIENT_SELECTION = 3;
  } else if ( $('table.scheduler.requested, table.scheduler.scheduling').length === 1 ) {
    MAX_CLIENT_SELECTION = 3;
  } else if ( $('table.scheduler.acknowledged').length === 1 ) {
    $('table .timeslot:not(.counterpart-selected)').addClass('disabled');
    MAX_CLIENT_SELECTION = 1;
  }

  $('#scheduler-container').on('click', '.timeslot', function(e){
    e.preventDefault();

    var $this = $(this)
    $this.toggleClass('checked');
    var $input = $this.find('input');
    if ($input.is(':checked')) {
      $input.attr('checked', false); 
    } else {
      $input.attr('checked', true);
    }
    var $container = $this.closest('.scheduler');

    var checked = $container.find('.timeslot.checked')

    if( checked.length >= MAX_CLIENT_SELECTION) {
      $container.find('.timeslot:not(.checked)').addClass('disabled');
    } else if ( $('table.scheduler.acknowledged').length === 1 ) {
      $container.find('.timeslot.counterpart-selected').removeClass('disabled');
    } else {
      $container.find('.timeslot').removeClass('disabled');
    }
    
    var $submitBtn = $('input.submit-request');
    var $submitHint = $('.submit-hint');
    if( checked.length < MIN_CLIENT_SELECTION ) {
      $submitBtn.attr('disabled', true);
      $submitHint.removeClass('d-none');
      $('[data-toggle="popover"]').popover('show');
    } else {
      $submitBtn.attr('disabled', false);
      $submitHint.addClass('d-none');
      $('[data-toggle="popover"]').popover('dispose');
    }

    if ( $container.hasClass('mobile-interface') ) {
      updateDayLinkAsterisk(this);
    }

    updateSummaryTimes();
  });

  $('#scheduler-container').on('click', '.day-link', function(e){
    e.preventDefault();

    var $this = $(this);
    var $dayPanel = $($this.data('target'));
    $('.scheduler .day-link, .scheduler .day-panel').removeClass('active');

    $this.addClass('active');
    $dayPanel.addClass('active')
  });

  $('.conferences').on('change', 'input[name="conference[ctype]"]', function(e) {
    $('#method-placeholder').text($(this).val());
  });


  $('a#schedule-accept').click(function(e) {
    e.preventDefault();

    var $modal = $('#confirm-accept-modal');
    var acceptedValue = $('input[name="accept-time"]:checked').val()
    var prettyValue = $('input[name="accept-time"]:checked').data('pretty-date');

    $modal.find('#accepted-time').text(prettyValue);
    $modal.find('#scheduled-datetime').attr('value', acceptedValue);

    $modal.modal('show');
  })

  $('a#schedule-propose').click(function(e) {
    e.preventDefault();

    var $scheduleForm = $('#schedule-form');

    $scheduleForm.removeClass('d-none');
    $('[data-toggle="popover"]').removeClass('d-none').popover('show');

    $([document.documentElement, document.body]).animate({
        scrollTop: $scheduleForm.offset().top
    }, 1000);
  })

  $('a#schedule-decline').click(function(e) {
    e.preventDefault();
    $('#decline-modal').modal('show');
  })

  $('a#schedule-cancel').click(function(e) {
    e.preventDefault();
    $('.cancellation-modal').modal('show');
  })

  $('a#schedule-conflict').click(function (e) {
    e.preventDefault();
    $('.decline-schedule').removeClass('d-none');
    $('.decline-other').addClass('d-none');
    $('.decline-fields').removeClass('d-none');
  })

  $('a#other-reason').click(function (e) {
    e.preventDefault();
    $('.decline-other').removeClass('d-none');
    $('.decline-schedule').addClass('d-none');
    $('.decline-fields').removeClass('d-none');
  })


  function updateDayLinkAsterisk(timeslot) {
    var $timeslot = $(timeslot);
    var target_id = $timeslot.data('target');
    var $link = $timeslot.closest('.scheduler').find(target_id);
    var $panel = $timeslot.closest('.day-panel');

    if ( $panel.find('.timeslot.checked, .timeslot.counterpart-selected').length > 0 ) {
      $link.addClass('has-selection');
    } else {
      $link.removeClass('has-selection');
    }
  }

  function updateSummaryTimes() {
    var $timesList = $('.summary #times-placeholder');
    $timesList.empty();

    var $sorted = $('.scheduler .timeslot.checked').sort( function (a,b) {
      var contentA = $(a).data('time');
      var contentB = $(b).data('time');
      return (contentA < contentB) ? -1 : (contentA > contentB) ? 1 : 0;
    });

    $timesList.append('<div class="row times-header"><div class="col-6"><strong>Your Time</strong></div><div class="col-6"><strong>Their Time</strong></div></div>')

    $sorted.each(function() {
      var $time = $(this);
      $timesList.append('<div class="row times-details"><div class="col-6">' + $time.data('pretty-date') + '</div><div class="col-6">' + $time.data('pretty-counterpart-date') + '</div></div>')
    })
  }

  $('.mobile-interface .timeslot').each(function() {
    updateDayLinkAsterisk(this);
  })

  if( $('.mobile-interface .day-link.has-selection').length > 0 ) {
    $('.mobile-interface .day-link').removeClass('active');

    var $activeLink = $('.mobile-interface .day-link.has-selection').first();
    $activeLink.addClass('active');
    $activeLink.click();
  }
});
