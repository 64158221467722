// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

$(document).ready(function () {
  $('#avatar-file-input').on('change', function(evt) {
    var file = evt.target.files[0];
    // 2621440 == 2.5M size
    if ((file && file.size) > 2621440) {
      alert('Our image file size limit is 2.5MB. Please upload an image smaller than our limit.');
      return;
    };
    
    var data = new FormData();
    data.append('user[avatar]', file);

    var path = $('#avatar-form').attr('action');
    $( document ).ajaxStart(function() {
      $( ".spinner" ).show();
    });

    $( document ).ajaxStop(function() {
      $( ".spinner" ).hide();
    });
    
    var cropper = $('#cropper');
    cropper.modal({ focus: true, show: true });
    
    var cropForm = $('#avatar-cropper-form');
    var image = new Image(URL.createObjectURL(file));
    var originalWidth = image.width;
    var originalHeight = image.height;
    var boxWidth = 500;
    var aspect = 1;

    cropForm.append("<input type='hidden' name='user[avatar_original_w]' id='user_avatar_original_w' value=" + originalWidth + ">");
    cropForm.append("<input type='hidden' name='user[avatar_original_h]' id='user_avatar_original_h' value=" + originalHeight + ">");
    cropForm.append("<input type='hidden' name='user[avatar_box_w]' id='user_avatar_box_w' value=" + boxWidth + ">");
    cropForm.append("<input type='hidden' name='user[avatar_aspect]' value=" + aspect + ">");

    cropForm.append("<input type='hidden' name='user[avatar_crop_x]' id='avatar_crop_x'>");
    cropForm.append("<input type='hidden' name='user[avatar_crop_y]' id='avatar_crop_y'>");
    cropForm.append("<input type='hidden' name='user[avatar_crop_w]' id='avatar_crop_w'>");
    cropForm.append("<input type='hidden' name='user[avatar_crop_h]' id='avatar_crop_h'>");

    $("#avatar_cropbox").html("<img src='" + URL.createObjectURL(file) + "' />");
    $('.modal-dialog').addClass("modal-dialog-scrollable modal-xl");

    init_papercrop(function () {
      cropper.on('hidden.bs.modal', function () {
        $('#avatar_cropbox img').data('Jcrop').destroy();
        $("#avatar_cropbox").html("");
      });
    });
    
    $('#finish_cropping').on('click', function(e) {
      e.preventDefault();
      
      var other_data = cropForm.serializeArray();
      $.each(other_data, function (key, input) {
        data.append(input.name, input.value);
      });
      
      $.ajax({
        url: path,
        type: 'PUT',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
      }).done(function (data) {
        if (data.error) {
          window.location.reload();
        } else {
          $('#avatar-image').attr('src', data.avatar_cropped_url);
          $('#nav-avatar-img').attr('src', data.avatar_cropped_url);
          cropper.modal('hide'); 
        }
      });
      
    })
  });

  function init_papercrop(callback) {
    update_crop = function(coords) {
      $("#avatar_crop_x").val(Math.round(coords.x));
      $("#avatar_crop_y").val(Math.round(coords.y));
      $("#avatar_crop_w").val(Math.round(coords.w));
      $("#avatar_crop_h").val(Math.round(coords.h));
    };

    $('#avatar_cropbox img').Jcrop({
      onChange    : update_crop,
      onSelect    : update_crop,
      setSelect   : [0, 0, 250, 250],
      aspectRatio : 1,
      boxWidth    : $("#user_avatar_box_w").val()
    }, function() {
      callback();
    });
  };

  $('#gallery-upload-input').on('change', function(evt) {
    var file = evt.target.files[0];
    // 2621440 == 2.5M size
    if ((file && file.size) > 2621440) {
      alert('Our image file size limit is 2.5MB. Please upload an image smaller than our limit.');
      return;
    };
    
    $('#gallery-upload-form').submit();
  });

  $('.dashboard-gallery-items').sortable({
    animation: 150,
    store: null,
    onUpdate: function(evt) {
      var item = evt.item;
      var id = item.getAttribute('data-sortable-id');
      var path = '/images/' + id;

      $.ajax({
        url: path,
        type: 'PUT',
        data: {
          'image' : {
            'position' : evt.newIndex + 1
          }
        }
      }).done(function(data) {
        // console.log(data);
      });
    }
  });
  
  $('.admin .gallery-items').sortable({
    animation: 150,
    store: null,
    onUpdate: function (evt) {
      var item = evt.item;
      var id = item.getAttribute('data-sortable-id');
      var item_type = item.getAttribute('data-sortable-type');
      var item_name = 'image'
      if (item_type == 'banner' || item_type == 'feature') {
        item_name = item_type + '_' + item_name
      }
      var path = '/admin/' + item_name + 's/' + id;
      var send_data = {}
      send_data[item_name] = {
        'position': evt.newIndex + 1
      }

      $.ajax({
        url: path,
        type: 'PUT',
        data: send_data
      }).done(function (data) {
        // console.log(data);
      });
    }
  });

  $(".profile-info-form").submit(function() {
    if($('#password').val() == '') {
      $('#password').attr('disabled', 'disabled');
      $('#password-confirmation').attr('disabled', 'disabled');
    }
    return true;
  });
});
