$(document).ready(function () {

  $('.projects').sortable({
    animation: 150,
    store: null,
    onUpdate: function (evt) {
      var item = evt.item;
      var id = item.getAttribute('data-sortable-id');

      $.ajax({
        url: '/admin/projects/' + id,
        type: 'POST',
        data: {
          'project': {
            'position': evt.newIndex + 1
          }
        }
      }).done(function (data) {
        // console.log(data);
      });
    }
  });

  function initAutoComplete () {
    if($('.typeahead').length > 0){
      $(".typeahead").typeahead({
        name: 'job_title',
        hint: false,
        local: Config.Admin.JOB_TITLES
      });
    }

    $('#add-another-job-title').click(function () {
      var jobTitleInput = $('input.typeahead:last');
      if (jobTitleInput.val()) {
        var newInput = $("<input type='text' class='typeahead' name='user[job_title_names][]''>");
        jobTitleInput.after(newInput);
        jobTitleInput.after($("<br>"));
        newInput.typeahead({
          name: 'job_title',
          hint: false,
          local: Config.Admin.JOB_TITLES
        });
      }
      return false;
    });
  }

  function initVideoHelper () {
    var $select = $('[name="user[video_provider]"]');
    setVideoLabel();
    $select.change(setVideoLabel);

    function setVideoLabel () {
      var labelText, provider = $select.val();
      var helpText = '';
      if (provider == 'youtube') {
        labelText = "https://youtu.be/";
        helpText = "Enter ONLY the characters following the backslash (/). Use the \"http://youtu.be/...\" url listed under \"Video Details\" rather than copying the video url from your browser's navigation bar.";
      } else if (provider == 'vimeo') {
        labelText = "http://vimeo.com/";
        helpText = "Enter ONLY the characters following the backslash (\"/\") sign.";
      } else {
        labelText = '';
        helpText = '';
      }
      $('#video_url_prefix').html(labelText);
      $('#video_help_text').html(helpText);
    }

  }

  $('#user_approved').on('change', function(e) {
    e.preventDefault();
    if ($(this).val() == 'false') {
      $('#user_active').val('false');
      $('#user_active_hidden').val('false');
      $('#user_featured').val('false');
      $('#user_featured_hidden').val('false');
    }
    $('#user_active').prop('disabled', $(this).val() == 'false');
    $('#user_featured').prop('disabled', $(this).val() == 'false');
  });

  $('#user_active').on('change', function(e) {
    e.preventDefault();
    if ($(this).val() == 'false') {
      $('#user_featured').val('false');
      $('#user_featured_hidden').val('false');
    }
    $('#user_featured').prop('disabled', $(this).val() == 'false');
  });

  $('#user_featured').on('change', function(e) {
    e.preventDefault();
    if ($(this).val() === 'false') {
      $('#user_active').prop('disabled', false);
    } else {
      $('#user_active').val('true');
      $('#user_active_hidden').val('true');
      $('#user_active').prop('disabled', true);
    }
  });

  $('input.search-query').quicksearch('table tbody tr');
  initAutoComplete();
  initVideoHelper();

});
